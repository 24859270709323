import {AfterViewInit, Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {DocumentUtilsPrivateService} from '../_services/document-utils-private.service';
import {ViewDictPrivateService} from '../_services/view-dict.service';
import {TemplateUtilsPrivateService} from '../_services/template-utils.service';
import {ExportUtilsService} from '../_services/export-utils.service';
import {UploaderComponent} from '../../uploader/uploader.component';
import {OrderListComponent} from '../orderlist/orderlist.component';
import {DocumentEditorComponent} from '../document-editor/document-editor.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AppMessageService} from '../../../_services/app-message.service';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {AuthService} from '../../../_services/auth.service';
import {ConfigService} from '../../../_services/config.service';
import {ClipboardService} from 'ngx-clipboard';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {UIUtils} from '../../../_utils/ui-utils';
import * as moment from 'moment';
import {showDynamicComponent} from '../_services/document-utils';
import {DynamicDirective} from '../../../_directives/dynamic.directive';

declare var $: any;
declare var pz: any;

@Component({
  selector: 'app-page-doceditor',
  templateUrl: './page-report-editor.component.html',
  styleUrls: ['../_css/documentEditor.css'],
  providers: [
    DocumentUtilsPrivateService,
    ViewDictPrivateService,
    TemplateUtilsPrivateService,
    ExportUtilsService
  ]
})
export class PageReportEditorComponent implements OnInit, AfterViewInit {
  @Input() classToolbar: string = '';
  @Input() classContent: string = '';

  previewShow: boolean = false;
  popupShow: boolean = false;
  popupHeader: string = '';
  popupContent: string = '';
  popupType = 'info';
  listDctDocs: any[] = [];
  listSubdocs: any[] = [];
  activeMenuId: string;
  processing: boolean = false;

  private idpar: string;
  private cdoc: string = '';
  private access: any = null;
  private editable: boolean = false;
  private fileRowId;
  private docState: number = 0;
  private grType: number = 0;

  @ViewChild(UploaderComponent, {static: false}) uploaderCmp;
  @ViewChild(OrderListComponent, {static: false}) orderListCmp;
  @ViewChild(DocumentEditorComponent, {static: false}) editorCmp;
  @ViewChild(DynamicDirective, {static: true}) private dynamicTemplate!: DynamicDirective;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private docService: DocumentUtilsPrivateService,
              private utlService: TemplateUtilsPrivateService,
              private dictService: ViewDictPrivateService,
              private messageService: AppMessageService,
              private exportService: ExportUtilsService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private _configService: ConfigService,
              private clipboardService: ClipboardService,
              private ngZone: NgZone,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const noReport: boolean = Object.keys(params).length === 0;
      if (!noReport) {
        this.idpar = params['id'];
      }
    });
  }

  ngAfterViewInit() {
    let _self = this;
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    $.extend($.jdm, {
      //
      isMobileDevice: function () {
        return _self.configService.deviceType > 0;
      },
      //
      save: function () {
      },
      saveWithoutValidating: function () {
        _self.ngZone.run(() => {
          _self.saveWithoutValidating();
        });
      },
      //
      saveWitValidating: function () {
        _self.ngZone.run(() => {
          _self.save(true);
        });
      },
      // submit data
      submit: function () {
        _self.ngZone.run(() => {
          _self.send();
        });
      },
      // confirm dialog
      confirm: function (title, message, clbYes, clbNo) {
        let clbAccept = typeof (clbYes) !== 'undefined' ? clbYes : null;
        let clbReject = typeof (clbNo) !== 'undefined' ? clbNo : null;
        _self.ngZone.run(() => {
          _self.confirmationService.confirm({
            message: message,
            header: title,
            icon: 'fa fa-question-circle',
            accept: () => {
              clbAccept();
            },
            reject: () => {
              clbReject();
            }
          });
        });
      },
      // info-dialog
      info_dialog: function (title, message, type) {
        _self.ngZone.run(() => {
          switch (type) {
            case 'error':
              _self.messageService.showError(message, title);
              break;
            case 'info':
              _self.messageService.showSuccess(message, title);
              break;
            default:
              _self.messageService.showWarn(message, title);
              break;
          }
        });
      },
      // open-dialog
      open_dialog: function (title, content, type?: string) {
        _self.ngZone.run(() => {
          _self.popupHeader = title;
          _self.popupContent = content;
          _self.popupShow = true;
          _self.popupType = type || 'info';
        });
      },
      // get country record
      getCountryRecord: function (code, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getStateRec(code).then(data => {
            if (clb) clb(data);
          });
        });
      },
      showUploadForm: function (rowId) {
        _self.ngZone.run(() => {
          _self.showUploadForm(rowId);
        });
      },
      // show Ordered list picker
      showOrderListForm: function (rowId) {
        _self.ngZone.run(() => {
          _self.showOrderListForm(rowId);
        });
      },
      // remove item from order list
      removeItemOrderListForm: function (rowId, val2del) {
        _self.ngZone.run(() => {
          _self.removeItemOrderListForm(rowId, val2del);
        });
      },
      // upload file data
      uploadFileData: function (rowId, fileData) {
        _self.ngZone.run(() => {
          _self.uploadFileData(rowId, fileData);
        });
      },
      // clear files data
      clearFileData: function (rowId) {
        _self.ngZone.run(() => {
          _self.clearFileData(rowId);
        });
      },
      // doenload files data
      downloadFileData: function (id) {
        _self.ngZone.run(() => {
          _self.downloadFileData(id);
        });
      },
      showRichTextEditor: function (rowId, value) {
        _self.ngZone.run(() => showDynamicComponent(_self, {rowId, value}));
      }
    });

    this._initParams(this.idpar);
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
  }

  private async _initParams(id: string) {
    this.listDctDocs = [];
    this.listSubdocs = [];
    this.idpar = id;
    this.cdoc = '*';
    this.editable = false;
    this.access = null;
    this.docState = 0;

    const _docStatus = await this.docService.getDocumentStatus(this.idpar);
    if (_docStatus) {
      if (_docStatus.errorCode) { // errors
        this.messageService.showWarn(_docStatus.message);
        return;
      } else { // no errors
        this.docState = _docStatus.docState || 0;
        this.activeMenuId = _docStatus.cdoc;
        this.cdoc = _docStatus.cdoc;
        this.editable = _docStatus.editable;
        this.access = _docStatus.optsAcc || {};

        // Retrieving additional form record, parameters, rules, groups
        const tmplVars = await this.utlService.getFormTemplateVars(this.cdoc);
        const dctDoc = tmplVars.sprdoc;
        // reset arrays
        this.listDctDocs = [];
        this.listSubdocs = [{iddoc: this.idpar, cdoc: this.cdoc}];
        //
        const data_1 = await this.docService.getListSubdocs(this.idpar);
        for (let i in data_1) {
          const rdata = data_1[i];
          this.listSubdocs.push({iddoc: rdata.id, cdoc: rdata.cdoc});
        }
        //
        if (dctDoc) {
          this.grType = dctDoc.project.grType;
          this.listDctDocs.push({docName: dctDoc.name, cdoc: dctDoc.cdoc});

          const data_2 = await this.utlService.getListAppendixDocs(this.cdoc);
          for (let i in data_2) {
            const rdata = data_2[i];
            if (rdata.state === 1) {
              this.listDctDocs.push({docName: ('' + rdata.docName), cdoc: rdata.cdoc});
            }
          }
        }
        let _self = this;
        const callbackGetData = (success, failure): void => {
          _self.ngZone.run(() => {
            _self.callbackGetRptData(this.idpar, null, success, failure) //todo
            if (dctDoc.style) {
              $('.dialog-wrapper, .dialog-wrapper .p-toolbar').css(dctDoc.style);
            } else {
              $('.dialog-wrapper, .dialog-wrapper .p-toolbar').removeAttr('style');
            }
          });
        }

        this.editorCmp.refreshVars(
          this.idpar, // iddoc
          _docStatus, // the document status
          tmplVars, // the template vars
          {
            id: this.authService.user.id || '?',
            email: this.authService.user.email || '?',
            username: this.authService.user.username || '?',
            fullname: this.authService.user.fullname || '?',
            orgLevel: _docStatus?.optsAcc?.orgLevel
          }, // auth record
          callbackGetData // callback
        );
      }
    }
  }

  save(outputCheck: boolean) {
    this.processing = true;
    //
    this.editorCmp.commit();
    this.editorCmp.reset();
    //
    const log = this.editorCmp.check();
    this.docService.save(
      this.idpar,
      this.idpar,
      this.cdoc,
      this.editorCmp.dynamicVars(),
      this.editorCmp.data(),
      log.errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.saved'));
            (pz.$inst).jqDm('wizardHighlight', []);
            // this.onProcess.emit({oper: CProcessType.Save, iddoc: this.idpar, status: res.status});
          } else if (res.status === 0) {
            this.messageService.showSuccess(this.translate.instant('message.document.savedWarn', {message: res.message}));
            // this.onProcess.emit({oper: CProcessType.Save, iddoc: this.idpar, status: res.status, logs: res.logs});
            if (outputCheck) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else {
            this.messageService.showError(res.message);
          }
          this.updateUploadedFileData(res);
        },
        error => {
          this.messageService.showMessage(error, '[Save]');
          if (this.fileRowId) {
            this.uploaderCmp.finish(false);
            this.fileRowId = null;
          }
        });
  }

  private updateUploadedFileData(res: any) {
    if (res.status === 1 || res.status === 0) {
      if (this.fileRowId) {
        this.uploaderCmp.finish(true);
        this.editorCmp.setCellValue(this.fileRowId + 'Blocks', '');
        this.fileRowId = null;
      }
    } else {
      if (this.fileRowId) {
        this.uploaderCmp.finish(false);
        this.fileRowId = null;
      }
    }
  }

  saveWithoutValidating() {
    //
    this.editorCmp.commit();
    //
    this.docService.saveWithoutValidate(this.idpar, this.editorCmp.dynamicVars(), this.editorCmp.data())
      .subscribe(
        data => {
          const res = data;
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  check() {
    this.processing = true;
    //
    this.editorCmp.reset();
    //
    this.docService.check(this.iddoc, this.idpar, this.cdoc, this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.validation.success'));
            (pz.$inst).jqDm('wizardHighlight', []);
            // this.onProcess.emit({oper: CProcessType.Check, status: res.status});
          } else if (res.status === 0) {
            this.messageService.showWarn(res.message);
            // this.onProcess.emit({oper: CProcessType.Check, status: res.status, logs: res.logs});
            if (res.logs) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else {
            this.messageService.showError(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error, '[Check]');
        });
  }

  send() {
    this.processing = true;
    //
    const iddoc = this.idpar;
    //
    // saving before sending
    if (this.access.accessWrite) {
      this.editorCmp.commit();
      this.editorCmp.reset();
      //
      this.docService.save(this.idpar, iddoc, this.cdoc, this.editorCmp.dynamicVars(), this.editorCmp.data(), this.editorCmp.check().errors)
        .subscribe(
          data => {
            const res = data;
            this.updateUploadedFileData(res);
            this.doSend(iddoc);
          },
          error => {
            this.messageService.showMessage(error);
          });
    } else {
      this.doSend(iddoc);
    }
  }

  private doSend(iddoc: string) {
    this.docService.send(iddoc)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            (pz.$inst).jqDm('wizardHighlight', []);
            this.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
            //this.onProcess.emit({oper: CProcessType.Send, status: 1, iddoc: iddoc});
            this.getDocumentStatus();
          } else if (res.status === 0) {
            this.messageService.showWarn(this.translate.instant('message.document.submit.warn'));
            // this.onProcess.emit({oper: CProcessType.Send, status: 0, iddoc: iddoc});
            this.sendForce(iddoc);
          } else {
            this.messageService.showWarn(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error, '[Send]');
        });
  }

  sendForce(iddoc: string) {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.submit.warnConfirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        _self.processing = true;
        _self.docService.sendForce(iddoc)
          .pipe(finalize(() => {
            _self.processing = false;
          }))
          .subscribe(
            data => {
              let res = data;
              if (res.status === 1) {
                _self.getDocumentStatus();
                _self.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
                //_self.onProcess.emit({oper: CProcessType.SendForce, iddoc: iddoc});
              } else {
                _self.messageService.showWarn(res.message);
              }
            },
            error => {
              _self.messageService.showMessage(error);
            });
      },
      reject: () => {
      }
    });
  }

  reset() {
    this.processing = true;
    //
    this.docService.reset(this.idpar)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          let res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.reopen.success'));
            this.getDocumentStatus();
            // this.onProcess.emit({ oper: CProcessType.Reset, iddoc: this.idpar });
          } else {
            this.messageService.showWarn(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  delete() {
    const _self = this;
    const iddoc = this.idpar;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        _self.processing = true;
        this.editorCmp.reset();
        _self.docService.delete(iddoc)
          .pipe(finalize(() => {
            _self.processing = false;
          }))
          .subscribe(
            data => {
              const res = data;
              if (res.status === 1) {
                _self.messageService.showSuccess(this.translate.instant('message.document.delete.success'));
                if (_self.idpar) {
                  this.router.navigate(['start']);
                }
                // _self.onProcess.emit({oper: CProcessType.Remove, iddoc: iddoc});
              } else {
                _self.messageService.showWarn(res.message);
              }
            },
            error => {
              _self.messageService.showMessage(error, '[Delete]');
            })
      },
      reject: () => {
      }
    });
  }

  export() {
    this.exportService.exportDocument(this.idpar);
  }

  copyLink() {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.copyLink'),
      header: this.translate.instant('message.header.warn'),
      icon: 'fas fa-exclamation',
      accept: () => {
        _self.getDocumentToken();
      },
      reject: () => {
      }
    });
  }

  private getDocumentToken() {
    this.docService.getDocumentToken(this.idpar)
      .subscribe(data => {
          this.clipboardService.copy(UIUtils.getPublicDocLink(data.message));
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  print2pdf() {
    this.processing = true;
    const fileName = this.cdoc + '_' + moment().format('YYYYMMDD_HHmmss') + '.pdf';
    const callback = (success: boolean): void => {
      if (!success) {
        this.messageService.showError(this.translate.instant('message.document.print.error'));
      }
      this.processing = false;
    }
    this.editorCmp.print2pdf(fileName, callback);
  }

  restoreEditor() {
    this.previewShow = false;
    this.editorCmp.restoreEditor();
  }

  private callbackGetRptData(iddoc: string, cdoc: string, success: (resp) => any, failure: (error) => any) {
    let clbErr = (error): void => {
      this.messageService.showMessage(error, '[callbackGetRptData]');
      if (typeof failure === 'function') {
        failure(error);
      }
    };
    this.docService.getDocumentJSONData(iddoc, null, cdoc, clbErr)
      .then(resp => {
        if (resp) {
          if (resp.access) {
            success(resp.data);
          } else {
            this.messageService.showError(this.translate.instant('message.document.open.notAllowed'));
            success({});
          }
        }
      });
  }

  isShowBtnCopyLink(): boolean {
    return !this.previewShow && this.grType == 2 /* SURVEY ^*/;
  }

  isDisabledBtnDocSave(): boolean {
    if (this.access === null) {
      return true;
    }
    return !(this.editable && this.access.accessWrite) || this.processing;
  }

  isDisabledBtnDocCheck(): boolean {
    if (this.access === null) {
      return true;
    }
    return !(this.editable && this.access.accessWrite) || this.processing;
  }

  isHiddenBtnDocCheck(): boolean {
    if (this.previewShow) {
      return true;
    }
    if (this.access === null) {
      return true;
    }
    return this.cdoc.length > 1 ? this.cdoc.startsWith('TBW') : true;
  }

  isDisabledBtnDocRemove(): boolean {
    if (this.access === null) {
      return true;
    }
    return !(this.editable && this.access.accessRemove) || this.processing;
  }

  isDisabledBtnDocSend(): boolean {
    if (this.access === null) {
      return true;
    }
    return !(this.editable === false && this.access.accessSubmit) || this.processing;
  }

  get isDisabledBtnDocExport(): boolean {
    return this.iddoc === '-1' || this.processing || !(this.access?.roleDownload || this.access?.fullAccess);
  }

  isDisabledBtnDocReset(): boolean {
    if (this.access === null) {
      return true;
    }
    return !(this.docState >= 2 && this.access.accessReopen) || this.processing;
  }

  isDisabledBtnCopyLink(): boolean {
    if (this.access === null) {
      return true;
    }
    return (this.docState >= 2) || this.processing;
  }

  private getDocumentStatus() {
    this.docService.getDocumentStatus(this.idpar)
      .then(resp => {
        this.access = resp.optsAcc || {};
        this.docState = resp.docState || 0;
        this.editable = resp.editable;
        this.editorCmp.setStatus(resp);
      });
  }

  get configService(): ConfigService {
    return this._configService;
  }

  get items(): MenuItem[] {
    return [
      {
        label: 'Save', icon: 'fa fa-floppy-o', disabled: this.isDisabledBtnDocSave(), command: () => {
          this.save(true);
        }
      },
      {
        label: 'Check', icon: 'fa fa-check', disabled: this.isDisabledBtnDocCheck(), command: () => {
          this.check();
        }
      },
      {
        label: 'Remove', icon: 'fa fa-trash', disabled: this.isDisabledBtnDocRemove(), command: () => {
          this.delete();
        }
      },
      {
        label: 'Export', icon: 'fa fa-download', disabled: this.isDisabledBtnDocExport, command: () => {
          this.export();
        }
      },
      {separator: true},
      {
        label: 'Refresh', icon: 'fa fa-refresh', command: () => {/* this.reload()*/
        }
      },
    ]
  }

  getToolbarClass(): string {
    return this.classToolbar.length > 0 ? this.classToolbar : '';
  }

  getContentClass(): string {
    return this.classContent.length > 0 ? this.classContent : '';
  }

  getMenuTitle(rdata: any): string {
    return `${this.isNewDocument(rdata) ? '<i class="fa fa-plus" aria-hidden="true"></i>&nbsp;' : '&nbsp;'}${rdata.docName}`;
  }

  isNewDocument(rdata: any): boolean {
    const _cdoc = rdata.cdoc;
    let _open: boolean = true;
    for (let i in this.listSubdocs) {
      const rdata = this.listSubdocs[i];
      if (rdata.cdoc === _cdoc) {
        _open = false;
        break;
      }
    }
    return _open;
  }

  getMenuClass(rdata: any): string {
    const _cdoc = rdata.cdoc;
    let _open: boolean = true;
    for (let i in this.listSubdocs) {
      const rdata = this.listSubdocs[i];
      if (rdata.cdoc === _cdoc) {
        _open = false;
        break;
      }
    }
    const _newCss = `${_open ? 'new-doc' : 'opened-doc'}`;
    return rdata.cdoc == this.activeMenuId ? `active ${_newCss}` : `${_newCss}`;
  }

  clickActiveMenu(data: any) {
  }

  showUploadForm(rowId: string) {
    this.fileRowId = rowId;
    this.uploaderCmp.show()
  }

  showOrderListForm(rowId: string) {
    this.orderListCmp.show(rowId)
  }

  removeItemOrderListForm(rowId: string, val2del: string) {
    let cellValue = pz.$inst[0].p.data[0][rowId];
    cellValue = cellValue.replace(new RegExp(`^${val2del}($|;)`), '');
    cellValue = cellValue.replace(new RegExp(`(;${val2del})($|;)`), '$2');
    pz.$inst.jqDm('setCellValue', 0, rowId, cellValue);
  }

  uploadFileData(rowId: string, fileData) {
    this.fileRowId = rowId;
    this.uploaderCmp.show();
    this.uploaderCmp.fileUploader(fileData);
  }

  clearFileData(rowId: string) {
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.file.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.clearFileDataInt(rowId)
      }
    });
  }

  private clearFileDataInt(rowId: string) {
    this.editorCmp.clearFileDataInt(rowId);
  }

  downloadFileData(id: string) {
    this.docService.getFileLink(this.idpar, id)
      .subscribe(
        data => {
          window.open(data, '_blank');
        },
        error => {
          this.messageService.showMessage(error, this.translate.instant('message.document.file.download.error'));
        }
      );

  }

  fileUploadSuccess(data: any) {
    this.editorCmp.fileUploadSuccess(this.fileRowId, data);
    this.save(true);
  }

  fileUploadFailed(event: any) {
    this.clearFileDataInt(this.fileRowId);
  }

  orderListSuccess(data: any) {
    this.editorCmp.orderListSuccess(this.fileRowId, data);
  }

  onclose() {
  }

  get iddoc(): string {
    return this.idpar;
  }

  get cDoc(): string {
    return this.cdoc;
  }

  get btnPdfVisible(): boolean {
    return this.access?.rolePrint || this.access?.fullAccess;
  }

  get isDisabledBtnPdf(): boolean {
    return this.processing;
  }

}
